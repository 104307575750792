angular.module("linkApp", ["common"])
    .config(translateConfig)
    .run(['$rootScope', '$state', '$translate', 'PageService', 'instructionsMFG',
        function ($rootScope, $state, $translate, PageService, instructionsLINK) {

            (function setLINKInstructionPages() {
                PageService.addPages(instructionsLINK);
            })();

            $rootScope._ = _;
            $translate.refresh();
        }]);

